@import "./colors";

@mixin pageLeftPadding($paddingLeft) {
   padding-left: $paddingLeft;

   @media screen and (max-width: 768px) {
      padding-left: 0;
   }
}

@mixin globalPageStyle($height) {
   position: relative;
   width: 100%;
   height: $height;
   padding-top: 5rem;
}

@mixin primaryButton($maxWidth) {
   height: 50px;
   width: 100%;
   max-width: $maxWidth;
   outline: none;
   border: none;
   background-color: $primaryGreen;
   color: #ffffff;
   border-radius: 10px;
}

@mixin outlineButton($maxWidth) {
   height: 50px;
   width: 100%;
   max-width: $maxWidth;
   outline: none;
   border: none;
   background-color: #ffffff;
   color: $primaryGreen;
   border-radius: 10px;
   border: 2px solid $primaryGreen;
}
