@import "../../stylesTemplate/colors";

.largeScreenSidebar {
   position: fixed;
   left: 0px;
   top: 0;
   height: 100%;
   width: 240px;
   padding-top: 4rem;
   background-color: $primaryGreen;
   color: #ffffff;
   display: flex;
   flex-direction: column;
   z-index: 50;
   transition: all 0.3s ease-in-out;

   @media screen and (max-width: 768px) {
      display: none;
   }
}

.mobileSidebar {
   position: fixed;
   top: 0;
   height: 100%;
   width: 240px;
   padding-top: 4rem;
   background-color: $primaryGreen;
   color: #ffffff;
   display: flex;
   flex-direction: column;
   z-index: 50;
   box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
   transition: all 0.3s ease-in-out;
}

.sidebarItem {
   padding: 1rem;
   cursor: pointer;
   transition: all 0.3s ease-in-out;

   @media screen and (min-width: 769px) {
      &:hover {
         background-color: $lightGreen;
         color: $darkGreen;
      }
   }
}

.itemActive {
   padding: 1rem;
   background-color: $lightGreen;
   color: $darkGreen;
   cursor: pointer;
}

.menuIcon {
   position: fixed;
   left: 20px;
   top: 20px;
   color: #ffffff;
   font-size: 1.5rem;
   cursor: pointer;
   z-index: 60;

   @media screen and (min-width: 768px) {
      display: none;
   }
}

.closeIcon {
   position: absolute;
   top: 20px;
   right: 20px;
   color: #ffffff;
   cursor: pointer;

   @media screen and (min-width: 768px) {
      display: none;
   }
}
