@import "../stylesTemplate/colors";

.onBoardingPage {
   width: 100%;
   height: 100vh;
   background-image: linear-gradient(to bottom, $primaryGreen, #ffffff);
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 1rem;
}

.authContainer {
   width: 100%;
   max-width: 900px;
   min-height: 500px;
   padding: 2rem;
   background: $darkGreen;
   color: #ffffff;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   @media screen and (max-width: 500px) {
      padding: 1rem;
   }
}

.inputContainer {
   position: relative;
   width: 100%;
   max-width: 400px;
   border-radius: 10px;
   overflow: hidden;
   display: flex;
   margin-bottom: 1.5rem;
}

.iconContainer {
   width: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: $primaryGreen;
   color: #ffffff;
}

.authInput {
   width: 100%;
   padding: 0.5rem;
   outline: none;
   border: none;
   color: $darkGreen;
}

.authButton {
   border: none;
   outline: none;
   border-radius: 10px;
   padding: 0.7rem 2rem;
   font-weight: 700;
   background: $primaryGreen;
   color: #ffffff;
}

.passwordIcon {
   position: absolute;
   right: 10px;
   top: 21%;
   font-size: 1.5rem;
   color: $primaryGreen;
   cursor: pointer;
}

.forgotPsw {
   margin-bottom: 1.5rem;
   cursor: pointer;
   text-decoration: underline;
}
