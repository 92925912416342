@import "./colors";

// font sizes
.font-size-12 {
   font-size: 12px;
}

.font-size-20 {
   font-size: 20px;
}

.font-size-36 {
   font-size: 36px;
}

// font colors
.dark-green-text {
   color: $darkGreen;
}

.primary-green-text {
   color: $primaryGreen;
}

.primary-red-text {
   color: $primaryRed;
}

.primary-yellow-text {
   color: $primaryYellow;
}

// font weights {
.open-300 {
   font-weight: 300;
}

.open-400 {
   font-weight: 400;
}

.open-700 {
   font-weight: 700;
}

.open-800 {
   font-weight: 800;
}

// effects
.cursor-pointer {
   cursor: pointer;
}
