@import "../../../stylesTemplate/colors";
@import "../../../stylesTemplate/mixins";

.modalHeader {
   padding-bottom: 0 !important;
}

.addCompanyBtn {
   @include primaryButton(200px);
}

.stepButton {
   @include outlineButton(200px);
}

.stepsContainer {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   height: 120px;
   margin-bottom: 1.5rem;
}

.axis {
   position: absolute;
   top: 50%;
   width: 100%;
   height: 3px;
   background-color: $darkGreen;
   z-index: 10;
}

.step {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 50px;
   height: 50px;
   border-radius: 50%;
   background-color: #ffffff;
   color: $darkGreen;
   border: 3px solid $darkGreen;
   font-size: 1.2rem;
   font-weight: 700;
   cursor: pointer;
   z-index: 15;
}

.activeStep {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 50px;
   height: 50px;
   border-radius: 50%;
   background-color: $darkGreen;
   color: #ffffff;
   font-size: 1.2rem;
   font-weight: 700;
   cursor: pointer;
   z-index: 15;
}

.listIcon {
   font-size: 1.5rem;
   color: $primaryGreen;
   margin-right: 0.5rem;
}

.infoInput {
   padding: 0.3rem;
   width: 100%;
   border: none;
   border-bottom: 1px solid $darkGreen;
   font-size: 1.1rem;
   outline: none;
   color: $darkGreen;

   &::placeholder {
      font-weight: 300;
   }
}

.employeeInput {
   @extend .infoInput;
   max-width: 400px;
}

.contactPersonInput {
   width: 100%;
   max-width: 400px;
   font-size: 14px;
   padding-left: 0.5rem;
   letter-spacing: 0.01rem;
   background: #ffffff;
   border: 1px solid #cacaca;
   border-radius: 5px;
   line-height: 25px;
   height: 35px;
   outline: none;
   margin-left: 1rem;

   @media screen and (max-width: 992px) {
      max-width: unset;
   }
}

.upload {
   width: 100%;
   height: 120px;
   padding: 1.5rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 10px;
   border: 1px dashed $darkGreen;
   cursor: pointer;
}

.phoneContainer {
   @media screen and (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start !important;

      .contactPersonInput {
         margin-left: 0;
         margin-top: 1rem;
      }
   }
}

.addIcon {
   font-size: 1.5rem;
   margin-right: 0.5rem;
}

.deleteIcon {
   font-size: 1.5rem;
   margin-left: 0.5rem;
   color: $primaryRed;
   cursor: pointer;
}

.addDepartment {
   width: 100%;
   height: 70px;
   border: 2px solid $darkGreen;
   color: $darkGreen;
   margin-top: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   transition: all 0.3s ease-in-out;

   @media screen and (min-width: 769px) {
      &:hover {
         background-color: $darkGreen;
         color: #ffffff;
      }
   }
}

.deleteDepartmentIcon {
   position: absolute;
   top: 0;
   right: 0;
   font-size: 1.5rem;
   color: $primaryRed;
   cursor: pointer;
}

.stepsBtnContainer {
   width: 100%;
   display: flex;
   justify-content: center;

   @media screen and (max-width: 400px) {
      flex-direction: column;
      align-items: center;

      button:first-child {
         margin-right: 0 !important;
         margin-bottom: 1rem;
      }
   }
}
