@import "../../stylesTemplate/mixins";

.errorImage {
   width: 100%;
   max-width: 500px;
   height: auto;
}

.backButton {
   @include primaryButton(300px);
   margin-top: 1.5rem;
}
