@import "../../stylesTemplate/colors";
@import "../../stylesTemplate/mixins";

.pageStyle {
   @include pageLeftPadding(15rem);
   @include globalPageStyle(100vh);
}

.addButton {
   position: fixed;
   bottom: 50px;
   right: 50px;
   width: 60px;
   height: 60px;
   border-radius: 50%;
   background-color: $darkGreen;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

   @media screen and (max-width: 992px) {
      width: 40px;
      height: 40px;

      svg {
         font-size: 1.5rem;
      }
   }

   @media screen and (max-width: 767px) {
      bottom: 30px;
      right: 30px;
   }

   svg {
      color: #ffffff;
      font-size: 2rem;
   }
}

.noContent {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 1rem;

   svg {
      width: 100%;
      max-width: 500px;
      height: auto;
      margin-top: 2rem;
   }
}
