@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap");
@import "./colors";
@import "./mixins";
@import "./tipography";

html,
body,
#root {
   height: 100%;
   width: 100%;
}

body {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: "Open Sans", sans-serif;
   color: #333333;
}

input,
textarea,
button {
   font-family: "Open Sans", sans-serif;
   font-weight: 400;
}

.cursor-pointer {
   cursor: pointer;
}

.App {
   min-height: 100vh;
   position: relative;
}

.globalPageStyle {
   position: relative;
   width: 100%;
   height: 100vh;
   padding-top: 5rem;
   padding-left: 1rem;
   padding-right: 1rem;
}

.pageNavigation {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 4rem;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-left: 15rem;
   background-color: $darkGreen;
   color: #ffffff;
   z-index: 48;

   @media screen and (max-width: 768px) {
      padding-left: 0;
   }
}

.pageLargeContainer {
   padding: 1rem;
   min-height: 100vh;
   width: 100%;
}

.modal-content {
   border-radius: 10px;
}

.modal-header,
.modal-footer,
.modal-content {
   border: none;
}

.modal-header,
.modal-body,
.modal-footer {
   @media screen and (min-width: 992px) {
      padding: 2.5rem;
   }
}

.react-tel-input .form-control {
   width: 100% !important;
   max-width: 300px !important;

   @media screen and (max-width: 992px) {
      max-width: unset !important;
   }
}

.check-label {
   font-size: 1rem !important;
   display: block;
   position: relative;
   padding-left: 35px;
   // margin-bottom: 12px;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;

   .check-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
   }

   .checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: $lightGreen;
      border-radius: 5px;
      border: 1px solid $primaryGreen;

      &::after {
         content: "";
         position: absolute;
         display: none;
         left: 8px;
         top: 3px;
         width: 7px;
         height: 15px;
         border: solid white;
         border-width: 0 3px 3px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
      }
   }

   .item-checkmark {
      @media screen and (max-width: 1068px) {
         top: -4px;
      }
   }

   .check-input:checked ~ .checkmark {
      background-color: $primaryGreen;
   }

   .check-input:checked ~ .checkmark:after {
      display: block;
   }
}

.Toastify__toast-theme--light {
   box-shadow: 0px 12px 10px -10px rgb(141, 139, 139);
}
